/* eslint-disable */
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/HomeView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  //
  {
    path: "/about",
    component: () => import("../views/AboutView.vue"),
  },
  //
  {
    path: "/policy",
    component: () => import("../views/PolicyView.vue"),
  },
  //
  {
    path: "/report",
    component: () => import("../views/ReportView.vue"),
  },
  //
  {
    path: "/policy_sidnft_private",
    component: () => import("../views/SidnftPrivateView.vue"),
  },
  //
  {
    path: "/policy_sidnft_service",
    component: () => import("../views/SidnftServiceView.vue"),
  },

  
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
