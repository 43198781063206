import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import moment from "moment";
// import { computed } from "vue";

// const win_build_date = computed(() => {
//   return Date.now();
// });


export default /*@__PURE__*/_defineComponent({
  __name: 'HomeView',
  setup(__props) {

/* eslint-disable */

const win_build_date = (date: string) => {
  var m = moment(date);
  return `
  ${date}
  <span class="sm">
    (${m.fromNow()}
  </span>)
  `;
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "flex justify-center" }, [
      _createElementVNode("div", null, [
        _createElementVNode("div", { class: "m-4 flex justify-center" }, [
          _createElementVNode("img", {
            class: "rounded-2xl w-32 h-32",
            src: "/images/1_icon.png",
            width: "256"
          })
        ]),
        _createElementVNode("div", { class: "mt-8 mx-4" }, "PLAY VIDEO"),
        _createElementVNode("div", null, [
          _createElementVNode("iframe", {
            width: "640",
            height: "360",
            src: "https://www.youtube.com/embed/P952f5BNa2s",
            title: "Add Force 1 V30 play demo",
            frameborder: "0",
            allow: "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share",
            referrerpolicy: "strict-origin-when-cross-origin",
            allowfullscreen: ""
          })
        ]),
        _createElementVNode("div", { class: "mt-8 mx-4" }, "screenshot"),
        _createElementVNode("div", { class: "flex justify-center" }, [
          _createElementVNode("img", {
            class: "m-2 w-64 h-38",
            src: "/images/3_phone_1.png",
            width: "512"
          }),
          _createElementVNode("img", {
            class: "m-2 w-64 h-38",
            src: "/images/3_phone_2.png",
            width: "512"
          })
        ])
      ])
    ], -1)),
    _cache[1] || (_cache[1] = _createElementVNode("div", { class: "m-4 flex justify-center" }, [
      _createElementVNode("div", { class: "w-32" }, [
        _createElementVNode("a", {
          href: "https://play.google.com/store/apps/details?id=com.sidnft.add_force_1",
          target: "_black"
        }, [
          _createElementVNode("img", {
            src: "/images/icon_play_store.png",
            alt: ""
          })
        ])
      ])
    ], -1))
  ], 64))
}
}

})