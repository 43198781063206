<template>
  <div class="absolute">
    <div class="flex">
      <div class="mt-2 p-1 py-1 border-2 border-black rounded-2xl font-bold">
        SIDNFT
      </div>
    </div>
  </div>
  <div class="flex justify-center">
    <div class="mb-4 p-4 text-4xl font-bold text-center">Add Force 1</div>
  </div>

  <!-- menu -->

  <nav>
    <div>
      <router-link class="tw-nav-item" :class="is_enable('/')" to="/"
        >Home</router-link
      >
    </div>
  </nav>

  <!-- <div class="tw_main">
    <nav class="flex justify-center"></nav>
  </div> -->

  <div class="mt-8 flex justify-center">
    <div class="tw_main">
      <main>
        <router-view />
      </main>
    </div>
  </div>

  <!-- footer -->
  <div class="m-8 flex w-full">
    <div class="text-left">
      <div class="mb-2 uppercase text-xl font-bold">sidnft</div>
      <div>
        <a
          href="/policy_sidnft_private"
          class="ml-4 mb-2 border-b-2 border-white"
        >
          private policy
        </a>
      </div>
      <div>
        <a
          href="/policy_sidnft_service"
          class="ml-4 mb-2 border-b-2 border-white"
        >
          service policy
        </a>
      </div>
    </div>

    <div class="pl-16 text-left">
      <div class="mb-2 text-xl font-bold">Add Force 1</div>
      <div>
        <a href="/policy" class="ml-4 mb-2 border-b-2 border-white"> policy </a>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useRoute } from "vue-router";
// import { reactive} from "vue";
var route = useRoute();

const is_enable = (input) => {
  //
  console.log("is_enable", route.fullPath, input);
  if (input == route.fullPath) {
    return "tw-border";
  }
  return "";
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Nanum+Gothic&family=Noto+Sans+KR:wght@100..900&display=swap");

#app {
  font-family: Helvetica, "Noto Sans KR", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 600;
  text-align: center;
  color: #2c3e50;
}

/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
} */

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

.tw-border {
  @apply border-2;
}
</style>
